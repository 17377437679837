import moment from "moment";
import i18n from "@/plugins/i18n";
const config = require("@/config/config");

export default {
  getServerUrlFromServerName(servers, serverName) {
    for (let i = 0; i < servers.length; i++) {
      if (serverName === servers[i].name) {
        return servers[i].url;
      }
    }
  },

  // 21st October 2021, 11:21:09 am
  formatTimestamp(timestamp) {
    if (i18n.locale == "pt") {
      return moment(timestamp).locale("pt").format("Do MMMM YYYY, h:mm:ss a");
    } else return moment(timestamp).format("Do MMMM YYYY, h:mm:ss a");
  },

  // 18 08 2021 11:57:49
  formatTimestamp1(timestamp) {
    return moment(timestamp).locale("pt").format("DD MM YYYY HH:mm:ss");
  },

  // 12 08 2021
  // 10:16:47
  formatTimestamp2(timestamp) {
    var formattedTimestamp =
      timestamp.slice(0, 11) + "\n" + timestamp.slice(11);
    return formattedTimestamp;
  },

  // 21/10/2021 10:16:47
  formatTimestamp3(timestamp) {
    if (i18n.locale == "pt") {
      return moment(timestamp).locale("pt").format("DD-MM-YYYY HH:mm:ss");
    } else return moment(timestamp).format("DD-MM-YYYY HH:mm:ss");
  },

  // 18/08/2021
  formatTimestamp4(timestamp) {
    return moment(timestamp).format("DD/MM/YYYY");
  },

  nameDevice(deviceId, devices) {
    for (let i = 0; i < devices.length; i++) {
      if (deviceId === devices[i].id) {
        return devices[i].name;
      }
    }
  },

  nameDataType(dataType, locale, typesOfData, typesOfDataPt) {
    dataType = Number(dataType);
    if (locale == "pt") {
      return typesOfDataPt[dataType];
    } else return typesOfData[dataType];
  },

  getDataType(dataType) {
    let dataOfType = "";
    switch (dataType) {
      // ENGLISH
      case "Temperature":
        dataOfType = "temp";
        break;
      case "Carbon Dioxide":
        dataOfType = "eco2";
        break;
      case "Noise":
        dataOfType = "dbavg";
        break;
      case "Humidity":
        dataOfType = "humidity";
        break;
      case "Light Intensity":
        dataOfType = "lux";
        break;
      case "Air Quality":
        dataOfType = "tvoc";
        break;
      case "Pressure":
        dataOfType = "pressure";
        break;
      case "Wifi Signal":
        dataOfType = "rssi";
        break;
      case "Light Temperature":
        dataOfType = "light_temp";
        break;
      case "Number of Devices":
        dataOfType = "devicesCount";
        break;
      case "People Flow":
        dataOfType = "devicesFlow";
        break;

      // PORTUGUES
      case "Temperatura":
        dataOfType = "temp";
        break;
      case "Dióxido de Carbono":
        dataOfType = "eco2";
        break;
      case "Ruído":
        dataOfType = "dbavg";
        break;
      case "Humidade":
        dataOfType = "humidity";
        break;
      case "Intensidade da Luz":
        dataOfType = "lux";
        break;
      case "Qualidade do Ar":
        dataOfType = "tvoc";
        break;
      case "Pressão":
        dataOfType = "pressure";
        break;
      case "Sinal Wifi":
        dataOfType = "rssi";
        break;
      case "Temperatura da Luz":
        dataOfType = "light_temp";
        break;
      case "Número de Dispositivos":
        dataOfType = "devicesCount";
        break;
      case "Fluxo de Pessoas":
        dataOfType = "devicesFlow";
        break;
      default:
        dataOfType = null;
        break;
    }
    return dataOfType;
  },

  defineDataMeasure(dataType) {
    switch (dataType) {
      // English
      case "Temperature":
        return "ºC";
      case "Carbon Dioxide":
        return "ppm";
      case "Noise":
        return "db's";
      case "Humidity":
        return "%";
      case "Light Intensity":
        return "lux";
      case "Air Quality":
        return "ppb";
      case "Pressure":
        return "hPa";
      case "Wifi Signal":
        return "dBm";
      case "Light Temperature":
        return "ºK";
      case "Number of Devices":
        return "un";
      case "People Flow":
        return "un";
      // PORTUGUES
      case "Temperatura":
        return "ºC";
      case "Dióxido de Carbono":
        return "ppm";
      case "Ruído":
        return "db's";
      case "Humidade":
        return "%";
      case "Intensidade da Luz":
        return "lux";
      case "Qualidade do Ar":
        return "ppb";
      case "Pressão":
        return "hPa";
      case "Sinal Wifi":
        return "dBm";
      case "Temperatura da Luz":
        return "ºK";
      case "Número de Dispositivos":
        return "un";
      case "Fluxo de Pessoas":
        return "un";
      default:
        return "";
    }
  },

  //20 seconds of margin is applied to each sample rate
  getGroupSampleRate(services) {
    var sr = null;

    if (services.sr5 === true) {
      sr = 320;
    } else if (services.sr10 === true) {
      sr = 620;
    } else if (services.sr20 === true) {
      sr = 1220;
    }
    return sr;
  },

  nameEventType(eventType, locale, typesOfEvent, typesOfEventPt) {
    if (eventType == null) {
      return;
    }
    eventType = Number(eventType);
    if (locale == "pt") {
      return typesOfEventPt[eventType];
    } else return typesOfEvent[eventType];
  },

  nameNotificationType(
    notification,
    locale,
    typesOfNotification,
    typesOfNotificationPt
  ) {
    if (notification == null) {
      return;
    }
    notification = Number(notification);
    if (locale == "pt") {
      return typesOfNotificationPt[notification];
    } else return typesOfNotification[notification];
  },

  nameRole(roleId) {
    let role = "";
    switch (roleId) {
      case 1:
        role = "Admin";
        break;
      case 2:
        role = "User";
        break;
      case 3:
        role = "Master";
        break;
    }
    return role;
  },

  // Converts a package database reference to its name.
  namePackage(refPackage) {
    let packages = [
      "individual",
      "multiple",
      "minius",
      "limia",
      "nerbis",
      "turia",
    ];

    return packages[refPackage - 1].toUpperCase();
  },

  // Converts a package name into its database reference.
  getPackageReference(packageName) {
    let packageNames = [
      "individual",
      "multiple",
      "minius",
      "limia",
      "nerbis",
      "turia",
    ];

    for (let i = 0; i < packageNames.length; i++) {
      if (packageName.toLowerCase() == packageNames[i]) {
        return i + 1;
      }
    }
    return null;
  },

  // Returns a device Object by getting an array of the group devices objects and a device name
  getDeviceObjByName(groupDevices, deviceName) {
    for (let i = 0; i < groupDevices.length; i++) {
      if (deviceName == groupDevices[i].name) {
        return groupDevices[i];
      }
    }
  },

  // Returns a group object. Takes in an array of group objects and a group name.
  getGroupObjByName(groups, groupName) {
    let group = [];
    for (let i = 0; i < groups.length; i++) {
      if (groupName == groups[i].name) {
        group = groups[i];
        return group;
      }
    }
    return null;
  },

  getServiceRefByName(serviceName) {
    let services = [
      ".csv Export",
      "Ext. Integration",
      "Notifications",
      "Adv. Portal",
      "Analytics",
      "Control",
      "Limited Portal",
      "Fee Basic",
      "8 Cards",
      "12 Cards",
      "sr 20",
      "sr 10",
      "sr 5",
      "No Subscription",
    ];

    for (let i = 0; i < services.length; i++) {
      if (serviceName === services[i]) {
        console.log(i + 1);
        return i + 1;
      }
    }
  },

  convertCondition(condition) {
    if (condition === 0) {
      return "";
    }
    return String.fromCharCode(condition);
  },

  buildServicesObject(services) {
    let userServices = {
      limitedPortal: false,
      advPortal: false,
      csvExport: false,
      extIntegration: false,
      analytics: false,
      control: false,
      notification: false,
      feeBasic: false,
      cards8: false,
      cards12: false,
      sr20: false,
      sr10: false,
      sr5: false,
    };

    for (let i = 0; i < services.length; i++) {
      switch (services[i]) {
        case 1:
          userServices.csvExport = true;
          break;
        case 2:
          userServices.extIntegration = true;
          break;
        case 3:
          userServices.notification = true;
          break;
        case 4:
          userServices.advPortal = true;
          break;
        case 5:
          userServices.analytics = true;
          break;
        case 6:
          userServices.control = true;
          break;
        case 7:
          userServices.limitedPortal = true;
          break;
        case 8:
          userServices.limitedPortal = false;
          userServices.advPortal = true;
          userServices.csvExport = true;
          userServices.extIntegration = true;
          userServices.analytics = true;
          userServices.control = true;
          userServices.feeBasic = true;
          break;
        case 9:
          userServices.cards8 = true;
          break;
        case 10:
          userServices.cards8 = true;
          userServices.cards12 = true;
          break;
        case 11:
          userServices.sr20 = true;
          break;
        case 12:
          userServices.sr20 = false;
          userServices.sr10 = true;
          break;
        case 13:
          userServices.sr20 = false;
          userServices.sr10 = false;
          userServices.sr5 = true;
          break;
      }
    }
    return userServices;
  },

  mqttUpdateOffsetsMessageBuilder(offsets, mac) {
    let messageId = offsets.refDevice;

    let message = {
      id: messageId,
      device: mac,
      task: "config",
      type: "offsets",
      stts751: {
        temperature: offsets.temperature * 10,
      },
      ccs811: {
        eCO2: offsets.co2 * 10,
        tVOC: offsets.airQuality * 10,
      },
      si7021: {
        humidity: offsets.humidity * 10,
        temperature: offsets.temperature * 10,
      },
      ics43434: {
        max_db: 0,
        avg_db: offsets.noise * 10,
        min_db: 0,
      },
      lps22hh: {
        pressure: offsets.pressure * 10,
        temperature: offsets.temperature * 10,
      },
    };
    return message;
  },

  mqttUpdateRssiThresholdMessageBuilder(storedRssiConfig, mac) {
    let messageId = storedRssiConfig.refDevice;

    let message = {
      id: messageId,
      device: mac,
      task: "config",
      type: "sniffer",
      value: storedRssiConfig.rssi,
    };
    return message;
  },

  mqttCheckFirmwareVersionMessageBuilder(device) {
    let message = {
      id: device.id,
      device: device.mac,
      task: "request",
      type: "version",
    };
    return message;
  },

  mqttUpdateFirmwareMessageBuilder(device) {
    let message = {
      id: device.id,
      device: device.mac,
      task: "fota",
      value: `${config.fota.https == 0 ? "http" : "https"}://${
        config.fota.host
      }:${config.fota.port}/fota/Welfare_Analysis.bin`,
    };
    return message;
  },

  getFloorNamesFromBlueprintObj(blueprintObj) {
    let floors = [];
    for (let i = 0; i < blueprintObj.length; i++) {
      floors.push(blueprintObj[i].name);
    }
    return floors;
  },

  getBlueprintPathByFloorName(blueprintObj, floorName) {
    let path = "";
    for (let i = 0; i < blueprintObj.length; i++) {
      if (blueprintObj[i].name === floorName) {
        path = blueprintObj[i].path;
        return path;
      }
    }
    path = null;
    return path;
  },

  getBlueprintIdByFloorName(blueprintsArray, floorName) {
    let blueprintId = "";
    for (let i = 0; i < blueprintsArray.length; i++) {
      if (blueprintsArray[i].name === floorName) {
        blueprintId = blueprintsArray[i].id;
        return blueprintId;
      }
    }
    blueprintId = null;
    return blueprintId;
  },
};
