<template>
  <v-layout column>
    <v-row align="center" justify="center">
      <v-col xs="9" sm="9" md="6" lg="6" xl="6" class="align-center">
        <div class="align-center text-center mt-2">
          <v-card elevation="6" max-width="400" style="margin: auto">
            <v-toolbar flat dense class="blue darken-3" dark>
              <v-toolbar-title>{{
                $t("titles.forgotPassword")
              }}</v-toolbar-title>
            </v-toolbar>
            <!--class that defines paddings(left, right, top && bottom-->
            <div class="mt-4 pl-4 pr-4 pt-2 pb-0">
              <v-text-field
                class="ma-4"
                v-model="email"
                label="Email"
                hideDetails
                filled
              ></v-text-field>
              <v-select
                :items="serversName"
                v-model="selectedServerName"
                :label="$t('button.chooseServer')"
                class="ma-4"
                filled
                dense
              >
              </v-select>
              <div class="red--text" v-html="error" />
              <div class="green--text" v-html="success" />
              <v-row>
                <v-col class="ma-4 mt-10 pt-0">
                  <v-btn
                    class="white--text mb-2"
                    style="center"
                    min-width="175"
                    color="blue darken-3"
                    elevation="2"
                    @click="retrievePassword"
                    >{{ $t("button.submit") }}</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import HelperMethods from "@/utilities/HelperMethods";
import ServersService from "@/services/ServersService";

export default {
  data() {
    return {
      email: null,
      error: null,
      success: null,
      serversName: [],
      selectedServerName: null,
      servers: null,
    };
  },

  async mounted() {
    try {
      let response = await ServersService.getServersList();

      this.servers = response.data;

      for (let i = 0; i < this.servers.length; i++) {
        this.serversName.push(this.servers[i].name);
      }
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    async retrievePassword() {
      // If no server was selected block the login attempt and throw an error
      if (this.selectedServerName === null) {
        this.error = "Select a server before submiting.";
        return;
      }
      
      let serverUrl = HelperMethods.getServerUrlFromServerName(
        this.servers,
        this.selectedServerName
      );

      // Store on the vuex store the, selected by the user, server
      this.$store.dispatch("setServer", serverUrl);

      try {
        const response = await AuthenticationService.passwordChangeRequestEmail(
          {
            email: this.email,
          }
        );
        console.log("response: ", response);
        this.success = response.data.message;
      } catch (err) {
        this.error = err.response.data.message;
        console.log("retrievePassword err: ", err);
      }
    },
  },
};
</script>
