import Api from "@/services/Api";

export default {

  // Creates a new entry on the "Users" table. 
  // Takes an object, credentials, which holds the register info (name, email, pass).
  register(credentials) {
    return Api().post("register", credentials);
  },

  // Creates a new entry on the "Users" table. 
  // Takes an object, credentials, which holds the register info (name, email, pass).
  registerCompany(credentials) {
    return Api().post("registerCompany", credentials);
  },

  // Takes an object, credentials, which holds the register info (email, pass).
  login(credentials) {
    return Api().post("login", credentials);
  },

  verifyUser(code) {
    this.$router.push({ name: 'VerifyUser' });
    return Api().get(`verifyUser/${code}`);
  },

  // Takes a String (email).
  passwordChangeRequestEmail(userEmail) {
    return Api().post("passwordChangeRequestEmail", userEmail);
  }
};
